// SLIDER
const swiper = new Swiper('.swiper-container', {
  speed: 1000,
  spaceBetween: 0,
  slidesPerView: 1,
  centeredSlides: true,
  autoplay: {
    delay: 4000,
    disableOnInteraction: false,
  },
  direction: 'horizontal',
  loop: true,
  pagination: {
    el: '.swiper-pagination',
    clickable: true,
  }
});
